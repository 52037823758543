import {
  Divider,
  Grid,
  InputAdornment,
  MenuItem,
  Stack,
  Link,
  Tooltip,
  Avatar,
} from "@mui/material";
import {
  LoadingButtonComponent,
  SwitchComponent,
  TextFieldComponent,
} from "components/ui-elements";
import React, { ChangeEvent, Fragment, useEffect, useState } from "react";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import { state } from "../../../../utils/States";
import MediaAssets from "../../../../assets/index";
import { FieldArray, getIn } from "formik";
import { minAndSecHourSet, shouldNumberOnly } from "utils/ValidationUtils";
import {
  GeneralInfoConst,
  QuarterlyMonthList,
  HalfYearMonthsList,
  monthsConstants,
} from "./GeneralInfoConstant";
import Typography from "@mui/material/Typography";
import styles from "./GeneralInfo.module.css";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import FileUploadActions from "redux-container/project-media-gallery-s3-upload/ProjectMediaGalleryUpload";
import { useDispatch, useSelector } from "react-redux";
import Loader from "components/loader/Loader";
import {
  IInitinalValueProjectContent,
  InitinalValueProjectContent,
} from "../InitinalValuesProjectContent";
import { useFormikContext } from "formik";
import { previousYearsConstant } from "utils/DateFormatterUtils";
import { launchPhasesAction } from "redux-container/project-management/launch-phases/LaunchPhasesRedux";
import { useLocation } from "react-router-dom";
import ImageUploaderForGeneralInfo from "./ImageUploaderForGeneralInfo";
import projectSaveAsDraftAction from "redux-container/project-management/project-drafts/ProjectDraftsRedux";
import CrmUpdatesDetailsDialog from "../crm-updates-details-dialog/CrmUpdatesDetailsDialog";
import { crmProjectUpdatesAction } from "redux-container/project-management/crm-updates/CrmUpdates";
import FlashMessageDialog from "components/flash-message-dialog/FlashMessageDialog";

const actionDispatch = (dispatch: any) => {
  return {
    deleteFile: (data: any) =>
      dispatch(FileUploadActions.fileDeleteRequest(data)),
    fileUpload: (data: any) =>
      dispatch(FileUploadActions.fileUploadRequest(data)),
    getLaunchPhasesRequest: (id: any) =>
      dispatch(launchPhasesAction.getLaunchPhasesRequest(id)),
    getCrmFetchUpdates: (id: any) =>
      dispatch(crmProjectUpdatesAction.getCrmProjectUpdatesRequest(id)),
    updateProjectSaveAsDraft: (data: any) =>
      dispatch(projectSaveAsDraftAction.updateProjectDraftRequest(data)),
    addProjectSaveAsDraft: (data: any) =>
      dispatch(projectSaveAsDraftAction.addProjectDraftRequest(data)),
    setCrmFetchUpdatesState: (key: any, value: any) =>
      dispatch(crmProjectUpdatesAction.setCrmProjectUpdatesState(key, value)),
  };
};

export default function GeneralInformation() {
  const {
    // fileUpload,
    // deleteFile,
    getCrmFetchUpdates,
    setCrmFetchUpdatesState,
  } = actionDispatch(useDispatch());
  const location: any = useLocation();
  const isLoading = useSelector(
    (state: any) => state?.projectLaunchPhases?.isLoading
  );

  const fetchLaunchPhases = useSelector(
    (state: any) => state?.projectLaunchPhases?.fetchedLaunchPhasesData
  );

  const isViewMode = useSelector(
    (state: any) => state?.projectContentManagement?.isViewMode
  );

  const isFetchCrmUpdatesLoading = useSelector(
    (state: any) => state?.crmFetchUpdates?.isLoading
  );

  const isFetchCrmUpdatesSucess = useSelector(
    (state: any) => state?.crmFetchUpdates?.isSuccess
  );

  const fetchCrmUpdatesError = useSelector(
    (state: any) => state?.crmFetchUpdates?.error
  );

  const {
    values,
    handleChange,
    setFieldValue,
    handleBlur,
    errors,
    setFieldError,
    touched,
  } = useFormikContext<IInitinalValueProjectContent>();

  const projectValues: any = values;
  const crmLaunchPhase = projectValues?.crmLaunchPhase;
  const crmProject = projectValues?.crmProject;

  useEffect(() => {
    if (values?.reraDetails?.reraNumbers?.length < 1) {
      setFieldValue("reraDetails.reraNumbers", [""]); // adding reraNumber value if there is no value to render atleast one field.
    }
  }, [values?.reraDetails?.reraNumbers?.length]);

  useEffect(() => {
    const fetchedProjectAddress =
      fetchLaunchPhases?.crmProject?.location?.split(" ");
    if (!values?.pdpDisplayName) {
      setFieldValue("pdpDisplayName", values?.launchName);
    }
    crmLaunchPhase?.campaignCode &&
    setFieldValue("campaignCode", crmLaunchPhase.campaignCode ?? "");
    if (location?.state?.isNew && !location?.state?.isDraft) {
      setFieldValue("crmLaunchPhaseId", fetchLaunchPhases?.id);
      setFieldValue("launchName", fetchLaunchPhases?.launchName);
      setFieldValue("enquiryTitle", fetchLaunchPhases?.enquiryTitle);
      setFieldValue("pdpDisplayName", fetchLaunchPhases.pdpDisplayName);
      setFieldValue(
        "areaStartingFrom",
        !!fetchLaunchPhases?.areaStartingFrom
          ? fetchLaunchPhases?.areaStartingFrom?.toString()
          : "0"
      ); // checking for null value
      setFieldValue(
        "priceStartingFrom",
        !!fetchLaunchPhases?.priceStartingFrom
          ? fetchLaunchPhases?.priceStartingFrom?.toString()
          : "0"
      ); // checking for null value
      setFieldValue("crmProjectId", fetchLaunchPhases?.crmProject?.id);
      setFieldValue(
        "address.city",
        fetchedProjectAddress?.length > 0 ? fetchedProjectAddress[0] : ""
      );
      setFieldValue(
        "address.state",
        fetchedProjectAddress?.length > 1 ? fetchedProjectAddress[2] : ""
      );
      setFieldValue(
        "address.pinCode",
        fetchedProjectAddress?.length > 2 ? fetchedProjectAddress[3] : ""
      );
      setFieldValue(
        "address.longitude",
        fetchLaunchPhases?.crmProject?.longitude
      );
      setFieldValue(
        "address.latitude",
        fetchLaunchPhases?.crmProject?.lattitude
      );
      setFieldValue(
        "generalInfoEscalationGraph.crmLaunchPhaseName",
        fetchLaunchPhases?.launchName
      );
      setFieldValue(
        "generalInfoEscalationGraph.crmProjectName",
        fetchLaunchPhases?.crmProject?.name
      );
    }
    if (
      !location?.state?.isNew &&
      !location?.state?.isProjectClone &&
      !isViewMode
    ) {
      setFieldValue(
        "generalInfoEscalationGraph.crmLaunchPhaseName",
        crmLaunchPhase?.launchName
      );
      setFieldValue(
        "generalInfoEscalationGraph.crmProjectName",
        crmProject?.name
      );
    }
  }, []);

  // const handleMediaFileDelete = () => {
  //   deleteFile(values?.address.mapMedia.value.url.split("/").pop());
  //   setFieldValue("address.mapMedia.value.url", "");
  //   setFieldValue("address.mapMedia.name", "");
  //   setFieldValue("address.mapMedia.key", "");
  // };

  const handleTextInput = (event: ChangeEvent<HTMLInputElement>) => {
    setFieldValue(
      `${event.target.name}`,
      event.target.value.replace(/[^A-Za-z ]/gi, "")
    );
  };

  const handleFetchUpdate = () => {
    setOpenFetchUpdatesDialog(true);
    getCrmFetchUpdates(location?.state?.ProjectListCreationId);
  };

  const [openFetchUpdatesDialog, setOpenFetchUpdatesDialog] = useState(false);
  const [disableFetchUpdate, setDisableFetchUpdate] = useState(false);

  const handleCloseCrmUpdatesDialog = () => {
    setOpenFetchUpdatesDialog(false);
  };

  const handleCloseCrmUpdatesFlashDialog = () => {
    setCrmFetchUpdatesState("error", null);
  };

  useEffect(() => {
    // setting initial values if the values is null or undefined
    if (!values?.priceTransparency) {
      setFieldValue(
        "priceTransparency",
        InitinalValueProjectContent?.priceTransparency
      );
    }

    if (
      values?.priceTransparency?.projects?.length < 3 ||
      !values?.priceTransparency?.projects
    ) {
      setFieldValue(
        "priceTransparency.projects",
        InitinalValueProjectContent?.priceTransparency?.projects
      );
    }

    if (!values?.opportunityDoc) {
      setFieldValue(
        "opportunityDoc",
        InitinalValueProjectContent.opportunityDoc
      );
    }

    if (!values?.mediaGallery) {
      setFieldValue("mediaGallery", InitinalValueProjectContent.mediaGallery);
    }

    if (!values?.latestMediaGallery) {
      setFieldValue(
        "latestMediaGallery",
        InitinalValueProjectContent.latestMediaGallery
      );
    }
  }, []);

  return (
    <Fragment>
      {fetchCrmUpdatesError && (
        <FlashMessageDialog
          shouldOpen={fetchCrmUpdatesError}
          content={fetchCrmUpdatesError}
          isSuccess={false}
          cancelHandler={handleCloseCrmUpdatesFlashDialog}
        />
      )}
      <CrmUpdatesDetailsDialog
        shouldOpen={
          !isLoading &&
          !isFetchCrmUpdatesLoading &&
          isFetchCrmUpdatesSucess &&
          openFetchUpdatesDialog
        }
        handleClose={handleCloseCrmUpdatesDialog}
        projectValues={values}
        setDisableFetchUpdate={setDisableFetchUpdate}
      />
      {isLoading ? (
        <Loader />
      ) : (
        <div className={styles["general-info-postion"]}>
          <div className={styles["accordion-global-padding"]}>
            <Grid container spacing={2}>
              <Grid
                sx={{ marginTop: "15px" }}
                item
                xs={2}
                sm={2}
                md={1.5}
                lg={1.5}
                xl={1}
              >
                <TextFieldComponent
                  disabled
                  fullWidth
                  label={"Launch ID"}
                  name="crmLaunchPhaseId"
                  inputProps={{
                    "data-testId": "project-id-textfield",
                  }}
                  value={values?.crmLaunchPhaseId}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  style={{ marginTop: "4px" }}
                />
              </Grid>

              <Grid item xs={3} md={3} lg={3} xl={3}>
                <TextFieldComponent
                  fullWidth
                  required
                  name="launchName"
                  helpericon={MediaAssets.ic_info}
                  helpericoncontent={
                    "This will be the display name for this project on URL."
                  }
                  label={"URL Display Name"}
                  value={values?.launchName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  inputProps={{
                    maxLength: GeneralInfoConst.launchNameCharacterLimit,
                    "data-testId": "launch-name",
                  }}
                  disabled={isViewMode}
                  error={Boolean(
                    getIn(errors, "launchName") &&
                      getIn(touched, "launchName")
                  )}
                />
              </Grid>

              <Grid mt={2.5} item xs={3} md={3} lg={3} xl={3}>
                <TextFieldComponent
                  fullWidth
                  label={"CRM Launch Name"}
                  value={values?.generalInfoEscalationGraph?.crmLaunchPhaseName}
                  disabled
                />
              </Grid>

              <Grid mt={2.5} item xs={3} md={2.5} lg={2.5} xl={3}>
                <TextFieldComponent
                  fullWidth
                  label={"CRM Project Name"}
                  value={values?.generalInfoEscalationGraph?.crmProjectName}
                  disabled
                />
              </Grid>

              {location?.state?.isEditMode && !location?.isProjectClone && (
                <Grid
                  sx={{ textAlign: "end" }}
                  mt={3}
                  item
                  xs={2}
                  sm={2}
                  md={2}
                  lg={2}
                  xl={2}
                >
                  <LoadingButtonComponent
                    loading={isFetchCrmUpdatesLoading}
                    disabled={disableFetchUpdate}
                    onClick={handleFetchUpdate}
                  >
                    Fetch Updates
                  </LoadingButtonComponent>
                </Grid>
              )}
            </Grid>

            <Grid
              container
              spacing={2}
              mt={0.5}
              sx={{
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <Grid item xs={3} md={3} lg={3} xl={3}>
                <TextFieldComponent
                  fullWidth
                  name="enquiryTitle"
                  helpericon={MediaAssets.ic_info}
                  helpericoncontent={
                    "This will be displayed on footer and pop-up enquiry form"
                  }
                  label={"Enquiry Form Display Name"}
                  value={values?.enquiryTitle}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  inputProps={{
                    maxLength: GeneralInfoConst.launchNameCharacterLimit,
                    //ket limit same as launch name charcter limit
                    // "data-testId": "launch-name",
                  }}
                  disabled={isViewMode}
                />
              </Grid>
              <Grid item xs={3} md={3} lg={3} xl={3}>
                <TextFieldComponent
                  fullWidth
                  name="pdpDisplayName"
                  required
                  helpericon={MediaAssets.ic_info}
                  helpericoncontent={
                    "This will be the display name for this project on the Website & Customer App"
                  }
                  label={"Project Display Name"}
                  value={values?.pdpDisplayName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  inputProps={{
                    maxLength: GeneralInfoConst.launchNameCharacterLimit,
                    //ket limit same as launch name charcter limit
                    // "data-testId": "launch-name",
                  }}
                  disabled={isViewMode}
                  error={Boolean(
                    getIn(errors, "pdpDisplayName") &&
                      getIn(touched, "pdpDisplayName")
                  )}
                  fieldhelpertext={
                    getIn(touched, "pdpDisplayName") &&
                    getIn(errors, "pdpDisplayName")
                  }
                />
              </Grid>

              <Grid item xs={3} md={3} lg={3} xl={3}>
                <TextFieldComponent
                  fullWidth
                  name="campaignCode"
                  helpericon={MediaAssets.ic_info}
                  helpericoncontent={
                    "This is required for salesforce integration"
                  }
                  label={"Campaign Id"}
                  value={values?.campaignCode}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  inputProps={{
                    maxLength: GeneralInfoConst.launchNameCharacterLimit,
                  }}
                  disabled={isViewMode}
                />
              </Grid>

              <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
                <TextFieldComponent
                  fullWidth
                  required
                  disabled={true}
                  label={"Area Starting from"}
                  name="areaStartingFrom"
                  value={values?.areaStartingFrom}
                  onChange={(event) => {
                    if (shouldNumberOnly(event.target.value)) {
                      setFieldValue(`${event.target.name}`, event.target.value);
                    }
                  }}
                  inputProps={{
                    "data-testId": "min-area-range",
                  }}
                  onBlur={handleBlur}
                  fieldhelpertext={
                    getIn(touched, "areaStartingFrom") &&
                    getIn(errors, "areaStartingFrom")
                  }
                  error={Boolean(
                    getIn(errors, "areaStartingFrom") &&
                      getIn(touched, "areaStartingFrom")
                  )}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">sq ft</InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} mt={0.5}>
              <Grid item xs={3} spacing={1}>
                <TextFieldComponent
                  fullWidth
                  required
                  label={"Price Starting from"}
                  name="priceStartingFrom"
                  value={values?.priceStartingFrom}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    if (shouldNumberOnly(event.target.value)) {
                      setFieldValue(`${event.target.name}`, event.target.value);
                    }
                  }}
                  disabled={true}
                  onBlur={handleBlur}
                  inputProps={{
                    "data-testId": "min-price-range",
                  }}
                  fieldhelpertext={
                    getIn(touched, "priceStartingFrom") &&
                    getIn(errors, "priceStartingFrom")
                  }
                  error={Boolean(
                    getIn(errors, "priceStartingFrom") &&
                      getIn(touched, "priceStartingFrom")
                  )}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <CurrencyRupeeIcon fontSize="small" />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={3}>
                <Stack mt={2} ml={4} justifyContent={"start"} spacing={2}>
                  <Typography>Is Project Sold Out? </Typography>
                  <SwitchComponent
                    name="isSoldOut"
                    onChange={handleChange}
                    value={values?.isSoldOut}
                    checked={values?.isSoldOut}
                    disabled={isViewMode}
                  />
                </Stack>
              </Grid>
              <Grid item xs={3}>
                <Stack mt={2} ml={4} justifyContent={"start"} spacing={2}>
                  <Typography>Display in Enquiry form?</Typography>
                  <SwitchComponent
                    name="displayInLeadForm"
                    onChange={handleChange}
                    value={values?.displayInLeadForm}
                    checked={values?.displayInLeadForm}
                    disabled={isViewMode}
                  />
                </Stack>
              </Grid>
            </Grid>
          </div>
          <Divider />
          <div className={styles["accordion-global-padding"]}>
            <Grid container spacing={3}>
              <Grid item xs={7} sm={7} md={7} lg={7} xl={7}>
                <TextFieldComponent
                  fullWidth
                  required
                  disabled={isViewMode}
                  helpericon={MediaAssets.ic_info}
                  helpericoncontent={
                    "This will be appear on the project cards & list views."
                  }
                  name="shortDescription"
                  label={"Short Description"}
                  value={values?.shortDescription}
                  placeholder="Enter"
                  onChange={handleChange}
                  inputProps={{
                    maxLength: GeneralInfoConst.shortDescriptionCharacterLimit,
                  }}
                  onBlur={handleBlur}
                  fieldhelpertext={
                    getIn(touched, "shortDescription") &&
                    getIn(errors, "shortDescription")
                  }
                  error={Boolean(
                    getIn(errors, "shortDescription") &&
                      getIn(touched, "shortDescription")
                  )}
                />
              </Grid>

              <Grid item xs={11.3} sm={11.3} md={11.3} lg={11.3} xl={11.3}>
                <TextFieldComponent
                  fullWidth
                  required
                  multiline
                  maxRows={10}
                  minRows={3}
                  helpericon={MediaAssets.ic_info}
                  placeholder="Enter"
                  disabled={isViewMode}
                  helpericoncontent={
                    "This will be appear on the Project detail page."
                  }
                  name="fullDescription"
                  label={"Full Description"}
                  value={values?.fullDescription}
                  onChange={handleChange}
                  inputProps={{
                    maxLength: GeneralInfoConst.fullDescriptionCharacterLimit,
                  }}
                  onBlur={handleBlur}
                  fieldhelpertext={
                    getIn(touched, "fullDescription") &&
                    getIn(errors, "fullDescription")
                  }
                  error={Boolean(
                    getIn(errors, "fullDescription") &&
                      getIn(touched, "fullDescription")
                  )}
                />
              </Grid>
            </Grid>
          </div>
          <Divider />
          <div className={styles["accordion-global-padding"]}>
            <Stack
              className={styles["stack-container"]}
              justifyContent="flex-start"
              spacing={2}
            >
              <Typography variant={"h6"} color="#000" component={"h6"}>
                Escalation Graph
              </Typography>

              <SwitchComponent
                name="isEscalationGraphActive"
                onChange={handleChange}
                value={values?.isEscalationGraphActive}
                checked={values?.isEscalationGraphActive}
                disabled={isViewMode}
              />
              <Tooltip
                arrow
                title={
                  "Toggle is to show/hide this section on the Customer App"
                }
                placement="top"
              >
                <Avatar
                  className={styles["info-icon"]}
                  src={MediaAssets.ic_info}
                  variant="rounded"
                />
              </Tooltip>

              {/* Escalation Graph toggle button for web */}
              <Typography variant={"h6"} color="#000" component={"h6"}>
                Escalation Graph Web
              </Typography>
              <SwitchComponent
                name="isEscalationGraphWebActive"
                onChange={handleChange}
                value={values?.isEscalationGraphWebActive}
                checked={values?.isEscalationGraphWebActive}
                disabled={isViewMode}
              />
              <Tooltip
                arrow
                title={"Toggle is to show/hide this section on the website"}
                placement="top"
              >
                <Avatar
                  className={styles["info-icon"]}
                  src={MediaAssets.ic_info}
                  variant="rounded"
                />
              </Tooltip>
            </Stack>
            <Grid container spacing={2} my={1}>
              <Grid item xs={4}>
                <TextFieldComponent
                  required
                  name="generalInfoEscalationGraph.title"
                  value={values?.generalInfoEscalationGraph?.title}
                  placeholder="Enter Name"
                  label={"Section Heading"}
                  onChange={handleTextInput}
                  onBlur={handleBlur}
                  fieldhelpertext={
                    getIn(touched, "generalInfoEscalationGraph.title") &&
                    getIn(errors, "generalInfoEscalationGraph.title")
                  }
                  error={Boolean(
                    getIn(errors, "generalInfoEscalationGraph.title") &&
                      getIn(touched, "generalInfoEscalationGraph.title")
                  )}
                  disabled={isViewMode}
                  inputProps={{
                    maxLength: GeneralInfoConst.sectionHeadingCharacterLimit,
                  }}
                />
              </Grid>

              <Grid item xs={4}>
                <TextFieldComponent
                  disabled
                  name="generalInfoEscalationGraph.estimatedAppreciation"
                  value={
                    values?.generalInfoEscalationGraph?.estimatedAppreciation
                  }
                  placeholder="Enter"
                  label={"Project Estimated Appreciation"}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={4}>
                <TextFieldComponent
                  name="generalInfoEscalationGraph.xAxisDisplayName"
                  value={values?.generalInfoEscalationGraph?.xAxisDisplayName}
                  placeholder="Enter Name"
                  label={"Y-Axis Display Name *"}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  fieldhelpertext={
                    getIn(
                      touched,
                      "generalInfoEscalationGraph.xAxisDisplayName"
                    ) &&
                    getIn(errors, "generalInfoEscalationGraph.xAxisDisplayName")
                  }
                  error={Boolean(
                    getIn(
                      errors,
                      "generalInfoEscalationGraph.xAxisDisplayName"
                    ) &&
                      getIn(
                        touched,
                        "generalInfoEscalationGraph.xAxisDisplayName"
                      )
                  )}
                  disabled={isViewMode}
                  inputProps={{
                    maxLength: GeneralInfoConst.sectionHeadingCharacterLimit,
                  }}
                />
              </Grid>

              <Grid item xs={4}>
                <TextFieldComponent
                  required
                  name="generalInfoEscalationGraph.yAxisDisplayName"
                  value={values?.generalInfoEscalationGraph?.yAxisDisplayName}
                  placeholder="Enter"
                  label={"X-Axis Display Name "}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  fieldhelpertext={
                    getIn(
                      touched,
                      "generalInfoEscalationGraph.yAxisDisplayName"
                    ) &&
                    getIn(errors, "generalInfoEscalationGraph.yAxisDisplayName")
                  }
                  error={Boolean(
                    getIn(
                      errors,
                      "generalInfoEscalationGraph.yAxisDisplayName"
                    ) &&
                      getIn(
                        touched,
                        "generalInfoEscalationGraph.yAxisDisplayName"
                      )
                  )}
                  disabled={isViewMode}
                  inputProps={{
                    maxLength: GeneralInfoConst.sectionHeadingCharacterLimit,
                  }}
                />
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={4}>
                <TextFieldComponent
                  required
                  name="generalInfoEscalationGraph.dataPoints.dataPointType"
                  variant="outlined"
                  label={"Type"}
                  onChange={handleChange}
                  value={
                    values?.generalInfoEscalationGraph?.dataPoints
                      ?.dataPointType
                  }
                  select
                  fullWidth
                  onBlur={handleBlur}
                  fieldhelpertext={
                    getIn(
                      touched,
                      "generalInfoEscalationGraph.dataPoints.dataPointType"
                    ) &&
                    getIn(
                      errors,
                      "generalInfoEscalationGraph.dataPoints.dataPointType"
                    )
                  }
                  error={Boolean(
                    getIn(
                      errors,
                      "generalInfoEscalationGraph.dataPoints.dataPointType"
                    ) &&
                      getIn(
                        touched,
                        "generalInfoEscalationGraph.dataPoints.dataPointType"
                      )
                  )}
                  disabled={isViewMode}
                >
                  <MenuItem value={"Yearly"}>Yearly</MenuItem>
                  <MenuItem value={"Half Yearly"}>Half Year</MenuItem>
                  <MenuItem value={"Quaterly"}>Quaterly</MenuItem>
                  <MenuItem value={"Monthly"}>Monthly</MenuItem>
                </TextFieldComponent>
              </Grid>
            </Grid>

            {values?.generalInfoEscalationGraph?.dataPoints?.dataPointType ? (
              <FieldArray
                name="generalInfoEscalationGraph.dataPoints.points"
                render={(arrayHelper) => (
                  <div>
                    {values?.generalInfoEscalationGraph?.dataPoints?.points?.map(
                      (fieldValue: any, index: any) => (
                        <>
                          <Grid container spacing={2} my={1}>
                            <Grid
                              item
                              xs={1.5}
                              sm={1.5}
                              md={1.5}
                              lg={1.5}
                              xl={1.5}
                            >
                              <TextFieldComponent
                                required
                                select
                                disabled={isViewMode}
                                name={`generalInfoEscalationGraph.dataPoints.points[${index}].year`}
                                placeholder="Enter"
                                label={"Year"}
                                value={fieldValue?.year}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                fieldhelpertext={
                                  getIn(
                                    touched,
                                    `generalInfoEscalationGraph.dataPoints.points[${index}].year`
                                  ) &&
                                  getIn(
                                    errors,
                                    `generalInfoEscalationGraph.dataPoints.points[${index}].year`
                                  )
                                }
                                error={Boolean(
                                  getIn(
                                    errors,
                                    `generalInfoEscalationGraph.dataPoints.points[${index}].year`
                                  ) &&
                                    getIn(
                                      touched,
                                      `generalInfoEscalationGraph.dataPoints.points[${index}].year`
                                    )
                                )}
                              >
                                {previousYearsConstant()?.map((year: any) => (
                                  <MenuItem value={year}>{year}</MenuItem>
                                ))}
                              </TextFieldComponent>
                            </Grid>

                            {values?.generalInfoEscalationGraph?.dataPoints
                              ?.dataPointType === "Quaterly" ? (
                              <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                                <TextFieldComponent
                                  name={`generalInfoEscalationGraph.dataPoints.points[${index}].quater`}
                                  select
                                  disabled={isViewMode}
                                  label={"Select Quater"}
                                  onChange={handleChange}
                                  value={fieldValue.quater}
                                  defaultValue={fieldValue.quater}
                                >
                                  {QuarterlyMonthList?.map(
                                    (quater: any, index: any) => (
                                      <MenuItem
                                        key={index}
                                        value={quater.value}
                                      >
                                        {quater.label}
                                      </MenuItem>
                                    )
                                  )}
                                </TextFieldComponent>
                              </Grid>
                            ) : values?.generalInfoEscalationGraph?.dataPoints
                                ?.dataPointType === "Half Yearly" ? (
                              <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                                <TextFieldComponent
                                  name={`generalInfoEscalationGraph.dataPoints.points[${index}].halfYear`}
                                  disabled={isViewMode}
                                  select
                                  label={"Select Half Year"}
                                  onChange={handleChange}
                                  defaultValue={fieldValue?.halfYear}
                                  value={fieldValue?.halfYear}
                                >
                                  {HalfYearMonthsList?.map(
                                    (quater: any, index: any) => (
                                      <MenuItem
                                        key={index}
                                        value={quater.value}
                                      >
                                        {quater.label}
                                      </MenuItem>
                                    )
                                  )}
                                </TextFieldComponent>
                              </Grid>
                            ) : values?.generalInfoEscalationGraph?.dataPoints
                                ?.dataPointType === "Monthly" ? (
                              <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                                <TextFieldComponent
                                  disabled={isViewMode}
                                  name={`generalInfoEscalationGraph.dataPoints.points[${index}].month`}
                                  select
                                  label={"Select Month"}
                                  onChange={handleChange}
                                  defaultValue={fieldValue.month}
                                  value={fieldValue.month}
                                >
                                  {monthsConstants?.map(
                                    (month: any, index: any) => (
                                      <MenuItem key={index} value={month}>
                                        {month}
                                      </MenuItem>
                                    )
                                  )}
                                </TextFieldComponent>
                              </Grid>
                            ) : (
                              ""
                            )}

                            <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                              <TextFieldComponent
                                required
                                name={`generalInfoEscalationGraph.dataPoints.points[${index}].value`}
                                label={"Appreciation"}
                                onBlur={handleBlur}
                                disabled={isViewMode}
                                onChange={(event: any) => {
                                  if (shouldNumberOnly(event.target.value)) {
                                    setFieldValue(
                                      `${event.target.name}`,
                                      Number(event.target.value)
                                    );
                                  }
                                }}
                                value={fieldValue?.value}
                                InputProps={{
                                  endAdornment: (
                                    <div
                                      style={{
                                        width: "80px",
                                      }}
                                    >
                                      %
                                    </div>
                                  ),
                                }}
                                fieldhelpertext={
                                  getIn(
                                    touched,
                                    `generalInfoEscalationGraph.dataPoints.points[${index}].value`
                                  ) &&
                                  getIn(
                                    errors,
                                    `generalInfoEscalationGraph.dataPoints.points[${index}].value`
                                  )
                                }
                                error={Boolean(
                                  getIn(
                                    errors,
                                    `generalInfoEscalationGraph.dataPoints.points[${index}].value`
                                  ) &&
                                    getIn(
                                      touched,
                                      `generalInfoEscalationGraph.dataPoints.points[${index}].value`
                                    )
                                )}
                              />
                            </Grid>

                            <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
                              {values?.generalInfoEscalationGraph?.dataPoints
                                ?.points?.length > 2 &&
                                !isViewMode && (
                                  <Typography
                                    sx={{
                                      textDecoration: "underline",
                                      marginTop: "15px",
                                      marginRight: "-50px",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => {
                                      arrayHelper.remove(index);
                                    }}
                                  >
                                    <DeleteOutlineOutlinedIcon className="table-action-icon delete-icon" />
                                  </Typography>
                                )}
                            </Grid>
                          </Grid>

                          <Stack>
                            {values?.generalInfoEscalationGraph?.dataPoints
                              ?.points?.length -
                              1 ===
                              index && (
                              <Link
                                disabled={isViewMode}
                                underline="always"
                                component="button"
                                onClick={() =>
                                  arrayHelper.push({
                                    year: "",
                                    selectQuater: "",
                                    value: "",
                                  })
                                }
                              >
                                + Add more
                              </Link>
                            )}
                          </Stack>
                        </>
                      )
                    )}
                  </div>
                )}
              />
            ) : (
              ""
            )}
          </div>

          <Divider />

          <div className={styles["accordion-global-padding"]}>
            <Stack
              alignItems="center"
              justifyContent="flexStart"
              spacing={2}
              mb={1}
            >
              <Typography variant="h6" color="#000">
                Fomo Content
              </Typography>
            </Stack>
          </div>

          <div className={styles["accordion-global-padding"]}>
            <Grid container spacing={1}>
              <Grid item xs={2} sm={2} md={2} lg={2} mt={-0.3}>
                <Stack spacing={2} justifyContent="center">
                  <TextFieldComponent
                    name="fomoContent.noOfViews"
                    value={values?.fomoContent?.noOfViews}
                    placeholder="Enter"
                    label={"No. of Views "}
                    helpericon={MediaAssets.ic_info}
                    helpericoncontent={"This will autopopulate"}
                    disabled={isViewMode}
                    onChange={(event: any) => {
                      if (shouldNumberOnly(event.target.value)) {
                        setFieldValue(
                          `${event.target.name}`,
                          event.target.value
                        );
                      }
                    }}
                    onBlur={handleBlur}
                  />
                  <SwitchComponent
                    name={"fomoContent.isNoOfViewsActive"}
                    checked={values?.fomoContent?.isNoOfViewsActive}
                    value={values?.fomoContent?.isNoOfViewsActive}
                    onChange={handleChange}
                    disabled={isViewMode}
                  />
                </Stack>
              </Grid>

              <Grid item xs={2} sm={2} md={2} lg={2}>
                <Stack spacing={2} mt={0.8} justifyContent="center">
                  <TextFieldComponent
                    style={{ marginTop: "8px" }}
                    name="fomoContent.days"
                    value={values?.fomoContent?.days}
                    disabled={isViewMode}
                    placeholder="Enter"
                    label={"Days"}
                    onChange={(event: any) => {
                      if (shouldNumberOnly(event.target.value)) {
                        setFieldValue(
                          `${event.target.name}`,
                          event.target.value
                        );
                      }
                    }}
                    onBlur={handleBlur}
                  />
                  <SwitchComponent
                    value={values?.fomoContent?.isDaysActive}
                    checked={values?.fomoContent?.isDaysActive}
                    onChange={handleChange}
                    name="fomoContent.isDaysActive"
                    disabled={isViewMode}
                  />
                </Stack>
              </Grid>

              <Grid item xs={3} md={3} sm={3} lg={4} xl={3} my={1}>
                <Typography sx={{ marginTop: "-25px" }}>
                  Target Time (HH:MM:SS)
                </Typography>
                <Stack alignItems="center" spacing={1}>
                  <div>
                    <TextFieldComponent
                      disabled={isViewMode}
                      name="fomoContent.targetTime.hours"
                      value={values?.fomoContent?.targetTime?.hours}
                      onChange={(event) => {
                        if (shouldNumberOnly(event.target.value)) {
                          setFieldValue(
                            `${event.target.name}`,
                            event.target.value
                          );
                        }
                      }}
                      onBlur={handleBlur}
                      placeholder="HH"
                      inputProps={{
                        maxLength: 2,
                        "data-testId": "target-time-in-hours",
                      }}
                    />
                  </div>

                  <div style={{ marginBottom: "10px" }}>:</div>

                  <div>
                    <TextFieldComponent
                      fullWidth
                      disabled={isViewMode}
                      name="fomoContent.targetTime.minutes"
                      value={values?.fomoContent?.targetTime?.minutes}
                      onChange={(event) => {
                        if (shouldNumberOnly(event.target.value)) {
                          if (minAndSecHourSet(event.target.value)) {
                            setFieldValue(
                              `${event.target.name}`,
                              event.target.value
                            );
                          }
                        }
                      }}
                      onBlur={handleBlur}
                      placeholder="MM"
                      inputProps={{
                        maxLength: 2,
                        "data-testId": "target-time-in-minutes",
                      }}
                    />
                  </div>

                  <div style={{ marginBottom: "10px" }}>:</div>
                  <Stack spacing={1}>
                    <TextFieldComponent
                      fullWidth
                      disabled={isViewMode}
                      name="fomoContent.targetTime.seconds"
                      value={values?.fomoContent?.targetTime?.seconds}
                      onChange={(event) => {
                        if (shouldNumberOnly(event.target.value)) {
                          if (minAndSecHourSet(event.target.value)) {
                            setFieldValue(
                              `${event.target.name}`,
                              event.target.value
                            );
                          }
                        }
                      }}
                      onBlur={handleBlur}
                      inputProps={{
                        maxLength: 2,
                        "data-testId": "target-time-in-seconds",
                      }}
                      placeholder="SS"
                    />
                    <div style={{ marginBottom: "10px" }}>
                      <SwitchComponent
                        value={values?.fomoContent?.isTargetTimeActive}
                        checked={values?.fomoContent?.isTargetTimeActive}
                        onChange={handleChange}
                        name="fomoContent.isTargetTimeActive"
                        disabled={isViewMode}
                      />
                    </div>
                  </Stack>
                </Stack>
              </Grid>
            </Grid>
          </div>

          <Divider />

          <div className={styles["accordion-global-padding"]}>
            <Stack
              alignItems="center"
              justifyContent="flexStart"
              spacing={2}
              mb={1}
            >
              <Typography variant="h6" color="#000">
                Address
              </Typography>
            </Stack>
          </div>
          <div className={styles["accordion-global-padding"]}>
            <Grid container spacing={2}>
              <Grid item xs={3}>
                <TextFieldComponent
                  name="address.country"
                  variant="outlined"
                  label={"Country"}
                  onChange={handleChange}
                  value={values?.address?.country}
                  fullWidth
                  onBlur={handleBlur}
                  inputProps={{
                    "data-testId": "country",
                  }}
                  disabled={true}
                />
              </Grid>

              <Grid item xs={3}>
                <TextFieldComponent
                  select
                  name="address.state"
                  variant="outlined"
                  label={"State"}
                  value={values?.address?.state}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  fullWidth
                  disabled={isViewMode}
                >
                  {state.map((stateValue: string, key: number) => (
                    <MenuItem key={key} value={`${stateValue}`}>
                      {stateValue}
                    </MenuItem>
                  ))}
                </TextFieldComponent>
              </Grid>

              <Grid item xs={3}>
                <TextFieldComponent
                  fullWidth
                  name="address.city"
                  label={"City"}
                  value={values?.address?.city}
                  onBlur={handleBlur}
                  onChange={handleTextInput}
                  inputProps={{
                    "data-testId": "city",
                  }}
                  disabled={isViewMode}
                />
              </Grid>

              <Grid item xs={3}>
                <TextFieldComponent
                  name="address.pinCode"
                  variant="outlined"
                  label={"PIN/ZIP Code"}
                  fullWidth
                  onChange={(event) => {
                    if (shouldNumberOnly(event.target.value)) {
                      setFieldValue(`${event.target.name}`, event.target.value);
                    }
                  }}
                  onBlur={handleBlur}
                  value={values?.address?.pinCode}
                  disabled={isViewMode}
                  inputProps={{ maxLength: 6 }}
                />
              </Grid>
            </Grid>
          </div>

          <Divider />

          <div className={styles["accordion-global-padding"]}>
            <Stack
              className={styles["stack-container"]}
              justifyContent="flex-start"
              spacing={2}
            >
              <Typography variant={"h6"} color={"#000"} component={"h6"}>
                Map Details
              </Typography>

              <SwitchComponent
                name="address.isMapDetailsActive"
                onChange={(e: any) => {
                  handleChange(e);
                  if (e.target.value) {
                    setFieldError("address.mapMedia.value.url", "required");
                  } else {
                    setFieldError("address.mapMedia.value.url", "");
                  }

                  console.log("isMapDetailsActive", e, e.target.value);
                }}
                value={values?.address?.isMapDetailsActive}
                checked={values?.address?.isMapDetailsActive}
                disabled={isViewMode}
              />
              <Tooltip
                arrow
                title={
                  "Toggle is to show/hide this section on the Customer App"
                }
                placement="top"
              >
                <Avatar
                  className={styles["info-icon"]}
                  src={MediaAssets.ic_info}
                  variant="rounded"
                />
              </Tooltip>
            </Stack>
          </div>

          <div className={styles["accordion-global-padding"]}>
            <Grid container spacing={3}>
              <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                <div style={{ maxWidth: "350px" }}>
                  <TextFieldComponent
                    type="text"
                    label="longitude"
                    placeholder="Enter"
                    name={`address.longitude`}
                    onChange={handleChange}
                    helpericon={MediaAssets.ic_info}
                    placement="top"
                    helpericoncontent={`longitude`}
                    disabled
                    value={values?.address?.longitude}
                  />
                </div>
              </Grid>
              <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                <div style={{ maxWidth: "350px" }}>
                  <TextFieldComponent
                    type="text"
                    label="latitude"
                    placeholder="Enter"
                    name={`address.latitude`}
                    onChange={handleChange}
                    helpericon={MediaAssets.ic_info}
                    placement="top"
                    helpericoncontent={`latitude`}
                    disabled
                    value={values?.address?.latitude}
                  />
                </div>
              </Grid>
              <Grid item xs={3} sm={3} md={4} lg={4} xl={3}>
                <ImageUploaderForGeneralInfo />
              </Grid>
            </Grid>
          </div>

          {/* <Divider />

            <Stack style={{ margin: "10px" }} justifyContent={"end"}>
              <LoadingButtonComponent type="submit" disabled={!isValid}>
                Save
              </LoadingButtonComponent>
            </Stack> */}
        </div>
      )}
    </Fragment>
  );
}
