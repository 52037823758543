import { StatusTypeEnum } from "enumerations/timeLineStatusTypeEnum";

interface IDocuments {
  documentName: string;
  documentMedia: {
    name: string;
    key: string;
    value: {
      mediaType: string;
      size?: string | number;
      width?: string | number;
      height?: string | number;
      url: string;
    };
  };
}
interface IReraDocuments {
  phaseName: string;
  documents: IDocuments[];
}

export interface IInitinalValueProjectContent {
  crmLaunchPhaseId: number | null;
  crmProjectId: number | null;
  launchName: string;
  status: string;
  areaStartingFrom: string | null;
  priceStartingFrom: string | null;
  shortDescription: string;
  fullDescription: string;
  isEscalationGraphActive: boolean;
  isEscalationGraphWebActive: boolean;
  similarInvestmentSectionHeading: string;
  isSimilarInvestmentActive: boolean;
  numberOfSimilarInvestmentsToShow: number;
  isSoldOut: boolean;
  displayInLeadForm : boolean;
  enquiryTitle?:string;
  pdpDisplayName?:string;
  campaignCode?:string;
  // --------------------------generalInfoEscalationGraph-----------------
  generalInfoEscalationGraph: {
    title: string;
    crmLaunchPhaseName: string;
    crmProjectName: string;
    estimatedAppreciation: any;
    xAxisDisplayName: string;
    yAxisDisplayName: string;
    dataPoints: {
      dataPointType: string;
      points: {
        month: string;
        year: string;
        quater: string;
        halfYear: string;
        value: number | null;
      }[];
    };
  };
  // ------------------------fomoContent-------------------
  fomoContent: {
    isNoOfViewsActive: boolean;
    isDaysActive: boolean;
    isTargetTimeActive: boolean;
    noOfViews: number | null;
    days: number | null;
    targetTime: {
      hours: number | null;
      minutes: number | null;
      seconds: number | null;
    };
  };
  // ---------------address---------------------
  address: {
    isMapDetailsActive: boolean;
    city: string;
    state: string;
    country: string;
    pinCode: string;
    gpsLocationLink: string;
    latitude: string;
    longitude: string;
    mapMedia: {
      name: string;
      key: string;
      value: {
        url: string;
        size: number;
        width: number;
        height: number;
        mediaType: string;
      };
    };
  };

  // ---------------opportunityDoc--------------------
  opportunityDoc: {
    sectionHeading: {
      heading: string;
      subHeading: string;
    };
    //----------------Page Banner Image---------------------
    isBannerImageActive: boolean;
    bannerImage: {
      name: string;
      key: string;
      value: {
        url: string;
        size: number;
        width: number;
        height: number;
        mediaType: string;
      };
    };
    //--------------Why to Invest Card-------------------------
    whyToInvestMedia: {
      name: string;
      key: string;
      value: {
        url: string;
        size: number;
        width: number;
        height: number;
        mediaType: string;
      };
    };

    //--------------Project Doc Card Card-------------------------
    brochure: {
      name: string;
      key: string;
      value: {
        url: string;
        size: number;
        width: number;
        height: number;
        mediaType: string;
      };
    };

    //----------------Expected Growth----------------------
    isEscalationGraphActive: boolean;
    isEscalationGraphWebActive: boolean;
    escalationGraph: {
      title: string;
      projectEstimatedAppreciation: number | null;
      xAxisDisplayName: string;
      yAxisDisplayName: string;
      dataPoints: {
        dataPointType: string;
        points: {
          month: null | string;
          year: string;
          quater: null | string;
          halfYear: null | string;
          value: number | null;
        }[];
      };
    };
    isCurrentInfraStoryActive: boolean;
    isUpcomingInfraStoryActive: boolean;
    isTourismAroundActive: boolean;
    isAboutProjectsActive: boolean;
    isProjectAminitiesActive: boolean;
    //--------------------currentInfraStory-------------------------
    currentInfraStory: {
      heading: string;
      stories: {
        title: string;
        description: string;
        media: {
          name: string;
          key: string;
          value: {
            url: string;
            size: number;
            width: number;
            height: number;
            mediaType: string;
          };
        };
      }[];
    };
    //----------------Upcoming Infra Story------------------
    upcomingInfraStory: {
      heading: string;
      stories: {
        title: string;
        description: string;
        media: {
          name: string;
          key: string;
          value: {
            url: string;
            size: number;
            width: number;
            height: number;
            mediaType: string;
          };
        };
      }[];
    };
    // -------------------Tourism Around--------------------------
    tourismAround: {
      heading: string;
      stories: {
        title: string;
        description: string;
        media: {
          name: string;
          key: string;
          value: {
            url: string;
            size: number;
            width: number;
            height: number;
            mediaType: string;
          };
        };
      }[];
    };
    //--------------------------About The Project-----------------------
    aboutProjects: {
      heading: string;
      description: string;
      media: {
        name: string;
        key: string;
        value: {
          url: string;
          size: number;
          width: number;
          height: number;
          mediaType: string;
        };
      };
    };
    //--------------------------Project Amenities-----------------------
    projectAminities: {
      projectAminitiesSectionHeading: string;
      name: string;
      icon: {
        name: string;
        key: string;
        value: {
          url: string;
          size: number;
          width: number;
          height: number;
          mediaType: string;
        };
      };
    }[];
    //-----------------Page Footer----------------------------------
    isPageFooterActive: boolean;
    pageFooter: string;
  };

  //-------------------------inventoryBucketContents-------------------------
  isInventoryBucketActive: boolean;
  inventoryBucketContents: {
    name: string;
    displayName?: any;
    isSoldOut: boolean | any;
    shortDescription: string;
    areaRange: {
      from: string;
      to: string;
    };
    priceRange: {
      from: string;
      to: string;
    };
    crmInventoryBucketId: number | null;
  }[];
  // ------------------------------mediaGallery-----------------------------
  mediaGallerySectionHeading: string;
  mediaGallerySubHeading: string;
  isMediaGalleryActive: boolean;
  mediaGallery: {
    isCoverImageActive: boolean;
    isImagesActive: boolean;
    isVideosActive: boolean;
    isDroneShootsActive: boolean;
    isThreeSixtyImagesActive: boolean;
    isVirtualWalkthroughActive: boolean;
    // -------------------------------images----------------------
    images: {
      mediaContent: {
        name: string;
        key: string;
        value: {
          url: string;
          size: number;
          width: number;
          height: number;
          mediaType: string;
        };
      };
      mediaContentType: string;
      isPageWidthEnabled: boolean;
      thumbnail: string;
      name: string;
      status: string;
    }[];
    // ----------------------------videos------------------------------
    videos: {
      mediaContent: {
        name: string;
        key: string;
        value: {
          url: string;
          size: number;
          width: number;
          height: number;
          mediaType: string;
        };
      };
      mediaContentType: string;
      isPageWidthEnabled: boolean;
      thumbnail: string;
      name: string;
      status: string;
    }[];
    // ------------------------------------droneShoots------------------------
    droneShoots: {
      mediaContent: {
        name: string;
        key: string;
        value: {
          url: string;
          size: number;
          width: number;
          height: number;
          mediaType: string;
        };
      };
      mediaContentType: string;
      isPageWidthEnabled: boolean;
      thumbnail: string;
      name: string;
      status: string;
    }[];
    // --------------------------------------threeSixtyImages------------------
    threeSixtyImages: {
      mediaContent: {
        name: string;
        key: string;
        value: {
          url: string;
          size: number;
          width: number;
          height: number;
          mediaType: string;
        };
      };
      mediaContentType: string;
      isPageWidthEnabled: boolean;
      thumbnail: string;
      name: string;
      status: string;
    }[];

    // ----------------------- Virtual Video Walk Through ----------------
    virtualWalkthrough: {
      mediaContent: {
        name: string;
        key: string;
        value: {
          url: string;
          size: number;
          width: number;
          height: number;
          mediaType: string;
        };
      };
      mediaContentType: string;
      isPageWidthEnabled: boolean;
      thumbnail: string;
      name: string;
      status: string;
    }[];
  };

  // ------------------------------projectTimeLines------------------------
  projectCompletionDate: string;
  projectTimelines: {
    timeLineSectionHeading: string;
    priority: number;
    timeLines: {
      key: string;
      isSectionActive: boolean;
      category?: string;
      values: {
        displayName: string;
        isCtaActive: boolean;
        dateOfCompletion?: string;
        status?: string;
        webLink?: string;
        percentage?: number;
        toolTipDetails?: string;
        reraLink?: string;
        reraNumbers?: [string];
        imageTitle?: string;
        medias?: {
          name: string;
          key: string;
          value: {
            mediaType: string;
            size: number;
            width: number;
            height: number;
            url: string;
          };
          isActive: boolean;
        };
      };
    }[];
  }[];

  // ------------------------------keyPillars--------------------------
  isKeyPillarsActive: boolean;
  keyPillars: {
    heading: string;
    values: {
      name: string;
      icon: {
        name: string;
        key: string;
        value: {
          url: string;
          size: number;
          width: number;
          height: number;
          mediaType: string;
        };
      };
      points: string[];
    }[];
  };
  // ----------------------------------isLocationInfrastructure-----------------------
  isLocationInfrastructureActive: boolean;
  isLocationInfrastructureWebActive: boolean;
  locationInfrastructure: {
    heading: string;
    values: {
      name: string;
      hours: number | null;
      minutes: number | null;
      latitude: number | null;
      longitude: number | null;
      icon: {
        name: string;
        key: string;
        value: {
          url: string;
          size: number;
          width: number;
          height: number;
          mediaType: string;
        };
      };
    }[];
  };
  // ------------------------------isOffersAndPromotions------------------------
  isOffersAndPromotionsActive: any;
  offersAndPromotions: {
    name: string;
    key: string;
    value: {
      url: string;
      size: number;
      width: number;
      height: number;
      mediaType: string;
    };
  };

  // ------------------------------mappedFaqs----------------------------------
  mappedFaqSectionHeader: string;
  isMappedFaqSectionHeaderActive: boolean;
  frequentlyAskedQuestions: number[];

  mappedFaqs: number[];
  mappedCategories: number[];
  faqsToDisplayInDetails: {
    faqId: number;
    priority: number;
  }[];

  reraDetails: {
    companyNameAndAddress: string;
    reraNumbers: string[];

    isReraDetailsActive: boolean;
    isReraDetailsActiveForWeb: boolean;
    isReraDocumentsActive: boolean;
    sectionHeading: string; // this is rera document section heading
    reraDocuments: IReraDocuments[];
    reraCodes: { reraNumber: ""; qrCode: "" }[];
  };

  // --------------------------------latestMediaGallery------------------------------------------------
  isLatestMediaGalleryActive: boolean;
  latestMediaGalleryHeading: string;
  latestMediaGallerySectionHeading: string;
  latestMediaGallerySubHeading: string;
  latestMediaGallery: {
    isCoverImageActive: boolean;
    isImagesActive: boolean;
    isVideosActive: boolean;
    isDroneShootsActive: boolean;
    isThreeSixtyImagesActive: boolean;
    isVirtualWalkthroughActive: boolean;
    // -------------------------------images----------------------
    images: {
      mediaContent: {
        name: string;
        key: string;
        value: {
          url: string;
          size: number;
          width: number;
          height: number;
          mediaType: string;
        };
      };
      mediaContentType: string;
      isPageWidthEnabled: boolean;
      thumbnail: string;
      name: string;
      status: string;
    }[];
    // ----------------------------videos------------------------------
    videos: {
      mediaContent: {
        name: string;
        key: string;
        value: {
          url: string;
          size: number;
          width: number;
          height: number;
          mediaType: string;
        };
      };
      mediaContentType: string;
      isPageWidthEnabled: boolean;
      thumbnail: string;
      name: string;
      status: string;
    }[];
    // ------------------------------------droneShoots------------------------
    droneShoots: {
      mediaContent: {
        name: string;
        key: string;
        value: {
          url: string;
          size: number;
          width: number;
          height: number;
          mediaType: string;
        };
      };
      mediaContentType: string;
      isPageWidthEnabled: boolean;
      thumbnail: string;
      name: string;
      status: string;
    }[];
    // --------------------------------------threeSixtyImages------------------
    threeSixtyImages: {
      mediaContent: {
        name: string;
        key: string;
        value: {
          url: string;
          size: number;
          width: number;
          height: number;
          mediaType: string;
        };
      };
      mediaContentType: string;
      isPageWidthEnabled: boolean;
      thumbnail: string;
      name: string;
      status: string;
    }[];
    virtualWalkthrough: {
      mediaContent: {
        name: string;
        key: string;
        value: {
          url: string;
          size: number;
          width: number;
          height: number;
          mediaType: string;
        };
      };
      mediaContentType: string;
      isPageWidthEnabled: boolean;
      thumbnail: string;
      name: string;
      status: string;
    }[];
  };
  // -----------------------------------------projectCoverImages-----------------------------------------
  priceTransparency: {
    isPriceTransparencyActive: boolean;
    sectionHeading: string;
    projectName: string;
    priceTransparencyCTAName: string;
    shortDescription: string;
    projects: {
      skuName: string;
      areaStartingFrom: string;
      priceStartingFrom: string;
    }[];
    termsAndConditions: {
      isTermsAndConditionsActive: boolean;
      sectionHeading: string;
      description: string;
    };
  };
  // -----------------------------------------projectCoverImages-----------------------------------------
  similarInvestmentsWithPriority: {
    id: number;
    priority: number;
  }[];
  projectCoverImages: {
    // homePageMedia
    [key: string]: {
      name: string;
      key: string;
      value: {
        url: string;
        size: number;
        width: number;
        height: number;
        mediaType: string;
      };
    };
    // newInvestmentPageMedia: {
    //   name: string;
    //   key: string;
    //   value: {
    //     url: string;
    //     size: number;
    //     width: number;
    //     height: number;
    //     mediaType: string;
    //   };
    // };
    // collectionListViewPageMedia: {
    //   name: string;
    //   key: string;
    //   value: {
    //     url: string;
    //     size: number;
    //     width: number;
    //     height: number;
    //     mediaType: string;
    //   };
    // };
    // portfolioPageMedia: {
    //   name: string;
    //   key: string;
    //   value: {
    //     url: string;
    //     size: number;
    //     width: number;
    //     height: number;
    //     mediaType: string;
    //   };
    // };
    // chatListViewPageMedia: {
    //   name: string;
    //   key: string;
    //   value: {
    //     url: string;
    //     size: number;
    //     width: number;
    //     height: number;
    //     mediaType: string;
    //   };
    // };
    // chatPageMedia: {
    //   name: string;
    //   key: string;
    //   value: {
    //     url: string;
    //     size: number;
    //     width: number;
    //     height: number;
    //     mediaType: string;
    //   };
    // };
  };
  projectIcon: {
    name: string;
    key: string;
    value: {
      url: string;
      size: number;
      width: number;
      height: number;
      mediaType: string;
    };
  };
  projectPhoneNumber: string;
  otherSectionHeadings: {
    promises: {
      sectionHeading: string;
    };
    testimonials: {
      sectionHeading: string;
    };
    inventoryBucketContents: {
      sectionHeading: string;
      subHeading: string;
    };
    faqSection: {
      sectionHeading: string;
      subHeading: string;
      categoryNameList: string[];
    };
  };

  customerGuideLines: {
    name: string;
    key: string;
    value: {
      mediaType: string;
      size: number;
      width: number;
      height: number;
      url: string;
    };
    isActive: boolean;
  };
}

const InitinalValueProjectContent: IInitinalValueProjectContent = {
  crmLaunchPhaseId: null,
  crmProjectId: null,
  launchName: "",
  status: "1001",
  areaStartingFrom: null,
  priceStartingFrom: null,
  shortDescription: "",
  fullDescription: "",
  isEscalationGraphActive: false,
  isEscalationGraphWebActive: false,
  similarInvestmentSectionHeading: "",
  isSimilarInvestmentActive: false,
  numberOfSimilarInvestmentsToShow: 1,
  isSoldOut: false,
  displayInLeadForm : false,
  enquiryTitle:"",
  pdpDisplayName:"",
  campaignCode:"",
  // --------------------generalInfoEscalationGraph--------------------
  generalInfoEscalationGraph: {
    title: "",
    crmLaunchPhaseName: "",
    crmProjectName: "",
    estimatedAppreciation: 0,
    xAxisDisplayName: "",
    yAxisDisplayName: "",
    dataPoints: {
      dataPointType: "",
      points: [
        {
          month: "January",
          year: new Date().getFullYear().toString(),
          quater: "Q1 (Jan,Feb,Mar)",
          halfYear: "HY1 (Jan,Feb,Mar,Apr,Mar,Jun)",
          value: null,
        },
        {
          month: "January",
          year: new Date().getFullYear().toString(),
          quater: "Q1 (Jan,Feb,Mar)",
          halfYear: "HY1 (Jan,Feb,Mar,Apr,Mar,Jun)",
          value: null,
        },
      ],
    },
  },
  // ----------------------fomoContent-------------------------
  fomoContent: {
    isNoOfViewsActive: false,
    isDaysActive: false,
    isTargetTimeActive: false,
    noOfViews: 0,
    days: 0,
    targetTime: {
      hours: null,
      minutes: null,
      seconds: null,
    },
  },
  // ------------------------------address----------------------
  address: {
    isMapDetailsActive: false,
    city: "",
    state: "",
    country: "India",
    pinCode: "",
    gpsLocationLink: "not used",
    latitude: "",
    longitude: "",
    mapMedia: {
      name: "",
      key: "",
      value: {
        url: "",
        size: 0,
        width: 1024,
        height: 1024,
        mediaType: "image",
      },
    },
  },
  // ---------------opportunityDoc--------------------
  opportunityDoc: {
    sectionHeading: {
      heading: "",
      subHeading: "",
    },
    //----------------Page Banner Image---------------------
    isBannerImageActive: true,
    bannerImage: {
      name: "",
      key: "",
      value: {
        url: "",
        size: 0,
        width: 1024,
        height: 1024,
        mediaType: "image",
      },
    },
    //--------------Why to Invest Card-------------------------
    whyToInvestMedia: {
      name: "",
      key: "",
      value: {
        url: "",
        size: 0,
        width: 1024,
        height: 1024,
        mediaType: "image",
      },
    },
    //--------------Why to Invest Card-------------------------
    brochure: {
      name: "",
      key: "",
      value: {
        url: "",
        size: 0,
        width: 1024,
        height: 1024,
        mediaType: "image",
      },
    },
    //----------------Expected Growth----------------------
    isEscalationGraphActive: false,
    isEscalationGraphWebActive: false,
    escalationGraph: {
      title: "",
      projectEstimatedAppreciation: 0,
      xAxisDisplayName: "",
      yAxisDisplayName: "",
      dataPoints: {
        dataPointType: "Yearly",
        points: [
          {
            month: "January",
            year: new Date().getFullYear().toString(),
            quater: "Quarter 1(Jan,Feb,Mar)",
            halfYear: "First Half (Jan,Feb,Mar,Apr,Mar,Jun)",
            value: null,
          },
          {
            month: "January",
            year: new Date().getFullYear().toString(),
            quater: "Quarter 1(Jan,Feb,Mar)",
            halfYear: "First Half (Jan,Feb,Mar,Apr,Mar,Jun)",
            value: null,
          },
        ],
      },
    },
    isCurrentInfraStoryActive: false,
    isUpcomingInfraStoryActive: false,
    isTourismAroundActive: false,
    isAboutProjectsActive: false,
    isProjectAminitiesActive: false,
    //--------------------currentInfraStory-------------------------
    currentInfraStory: {
      heading: "",
      stories: [
        {
          title: "",
          description: "",
          media: {
            name: "",
            key: "",
            value: {
              url: "",
              size: 0,
              width: 1024,
              height: 1024,
              mediaType: "image",
            },
          },
        },
        {
          title: "",
          description: "",
          media: {
            name: "",
            key: "",
            value: {
              url: "",
              size: 0,
              width: 1024,
              height: 1024,
              mediaType: "image",
            },
          },
        },
        {
          title: "",
          description: "",
          media: {
            name: "",
            key: "",
            value: {
              url: "",
              size: 0,
              width: 1024,
              height: 1024,
              mediaType: "image",
            },
          },
        },
      ],
    },
    // ---------------Upcoming Infra Story------------------
    upcomingInfraStory: {
      heading: "",
      stories: [
        {
          title: "",
          description: "",
          media: {
            name: "",
            key: "",
            value: {
              url: "",
              size: 0,
              width: 1024,
              height: 1024,
              mediaType: "image",
            },
          },
        },
        {
          title: "",
          description: "",
          media: {
            name: "",
            key: "",
            value: {
              url: "",
              size: 0,
              width: 1024,
              height: 1024,
              mediaType: "image",
            },
          },
        },
        {
          title: "",
          description: "",
          media: {
            name: "",
            key: "",
            value: {
              url: "",
              size: 0,
              width: 1024,
              height: 1024,
              mediaType: "image",
            },
          },
        },
      ],
    },
    // -------------------Tourism Around--------------------------
    tourismAround: {
      heading: "",
      stories: [
        {
          title: "",
          description: "",
          media: {
            name: "",
            key: "",
            value: {
              url: "",
              size: 0,
              width: 1024,
              height: 1024,
              mediaType: "image",
            },
          },
        },
      ],
    },
    //--------------------------About The Project-----------------------
    aboutProjects: {
      heading: "",
      description: "",
      media: {
        name: "",
        key: "",
        value: {
          url: "",
          size: 0,
          width: 1024,
          height: 1024,
          mediaType: "image",
        },
      },
    },
    //--------------------------Project Amenities-----------------------
    projectAminities: [
      {
        projectAminitiesSectionHeading: "",
        name: "",
        icon: {
          name: "",
          key: "",
          value: {
            url: "",
            size: 0,
            width: 1024,
            height: 1024,
            mediaType: "image",
          },
        },
      },
    ],
    //-----------------Page Footer----------------------------------
    isPageFooterActive: false,
    pageFooter: "",
  },
  //-------------------------inventoryBucketContents-------------------------
  isInventoryBucketActive: true,
  inventoryBucketContents: [
    {
      name: "",
      isSoldOut: false,
      shortDescription: "",
      areaRange: {
        from: "",
        to: "",
      },
      priceRange: {
        from: "",
        to: "",
      },
      crmInventoryBucketId: null,
    },
  ],
  // ------------------------------mediaGallery-----------------------------
  mediaGallerySectionHeading: "",
  mediaGallerySubHeading: "Not Used",
  isMediaGalleryActive: false,
  mediaGallery: {
    isCoverImageActive: false,
    isImagesActive: false,
    isVideosActive: false,
    isDroneShootsActive: false,
    isThreeSixtyImagesActive: false,
    isVirtualWalkthroughActive: false,
    // -------------------------------images----------------------
    images: [],
    // ----------------------------videos------------------------------
    videos: [],
    // ------------------------------------droneShoots------------------------
    droneShoots: [],
    // --------------------------------------threeSixtyImages------------------
    threeSixtyImages: [],
    virtualWalkthrough: [],
  },
  // ------------------------------projectTimeLines------------------------
  projectCompletionDate: new Date(Date.now())
    .toLocaleString()
    .split(",")[0]
    .toString(),
  projectTimelines: [
    {
      timeLineSectionHeading: "",
      priority: 1,
      timeLines: [
        {
          key: "projectTimelines",
          isSectionActive: false,
          category: "",
          values: {
            displayName: "",
            isCtaActive: false,
            // imageTitle: "",
            dateOfCompletion: new Date(Date.now())
              .toLocaleString()
              .split(",")[0]
              .toString(),
            status: StatusTypeEnum.NOT_STARTED_YET,
            // webLink: "",
            percentage: 0,
            // toolTipDetails: "",
            reraLink: "",
            reraNumbers: ["0"],
            // medias: {
            //   name: "",
            //   key: "",
            //   value: {
            //     url: "",
            //     size: 0,
            //     width: 1024,
            //     height: 1024,
            //     mediaType: "image",
            //   },
            //   isActive: false,
            // },
          },
        },
      ],
    },
    {
      timeLineSectionHeading: "",
      priority: 2,
      timeLines: [
        {
          key: "projectTimelines",
          isSectionActive: false,
          category: "4002",
          values: {
            displayName: "",
            isCtaActive: false,
            imageTitle: "",
            dateOfCompletion: new Date(Date.now())
              .toLocaleString()
              .split(",")[0]
              .toString(),
            // status: StatusTypeEnum.NOT_STARTED_YET,
            // webLink: "",
            percentage: 0,
            toolTipDetails: "",
            // reraLink: "",
            // reraNumbers: [""],
            medias: {
              name: "",
              key: "",
              value: {
                url: "",
                size: 0,
                width: 1024,
                height: 1024,
                mediaType: "image",
              },
              isActive: false,
            },
          },
        },
        {
          key: "projectTimelines",
          isSectionActive: false,
          category: "4001",
          values: {
            displayName: "",
            isCtaActive: false,
            // imageTitle: "",
            dateOfCompletion: new Date(Date.now())
              .toLocaleString()
              .split(",")[0]
              .toString(),
            status: StatusTypeEnum.NOT_STARTED_YET,
            // webLink: "",
            percentage: 0,
            toolTipDetails: "",
            // reraLink: "",
            // reraNumbers: [""],
            // medias: {
            //   name: "",
            //   key: "",
            //   value: {
            //     url: "",
            //     size: 0,
            //     width: 1024,
            //     height: 1024,
            //     mediaType: "image",
            //   },
            //   isActive: false,
            // },
          },
        },
      ],
    },
    {
      timeLineSectionHeading: "",
      priority: 3,
      timeLines: [
        {
          key: "projectTimelines",
          isSectionActive: false,
          category: "4003",
          values: {
            displayName: "",
            isCtaActive: false,
            // imageTitle: "",
            dateOfCompletion: new Date(Date.now())
              .toLocaleString()
              .split(",")[0]
              .toString(),
            status: StatusTypeEnum.NOT_STARTED_YET,
            // webLink: "",
            percentage: 100,
            toolTipDetails: "",
            // reraLink: "",
            // reraNumbers: [""],
            // medias: {
            //   name: "",
            //   key: "",
            //   value: {
            //     url: "",
            //     size: 0,
            //     width: 1024,
            //     height: 1024,
            //     mediaType: "image",
            //   },
            //   isActive: false,
            // },
          },
        },
        {
          key: "projectTimelines",
          isSectionActive: false,
          category: "4003",
          values: {
            displayName: "",
            isCtaActive: false,
            // imageTitle: "",
            dateOfCompletion: new Date(Date.now())
              .toLocaleString()
              .split(",")[0]
              .toString(),
            status: StatusTypeEnum.NOT_STARTED_YET,
            // webLink: "",
            percentage: 0,
            toolTipDetails: "",
            // reraLink: "",
            // reraNumbers: [""],
            // medias: {
            //   name: "",
            //   key: "",
            //   value: {
            //     url: "",
            //     size: 0,
            //     width: 1024,
            //     height: 1024,
            //     mediaType: "image",
            //   },
            //   isActive: false,
            // },
          },
        },
        {
          key: "projectTimelines",
          isSectionActive: false,
          category: "4004",
          values: {
            displayName: "",
            isCtaActive: false,
            // imageTitle: "",
            dateOfCompletion: new Date(Date.now())
              .toLocaleString()
              .split(",")[0]
              .toString(),
            status: StatusTypeEnum.NOT_STARTED_YET,
            // webLink: "",
            percentage: 0,
            toolTipDetails: "",
            // reraLink: "",
            // reraNumbers: [""],
            // medias: {
            //   name: "",
            //   key: "",
            //   value: {
            //     url: "",
            //     size: 0,
            //     width: 1024,
            //     height: 1024,
            //     mediaType: "image",
            //   },
            //   isActive: false,
            // },
          },
        },
      ],
    },
    {
      timeLineSectionHeading: "",
      priority: 4,
      timeLines: [
        {
          key: "projectTimelines",
          isSectionActive: false,
          category: "",
          values: {
            displayName: "",
            isCtaActive: false,
            // imageTitle: "",
            dateOfCompletion: new Date(Date.now())
              .toLocaleString()
              .split(",")[0]
              .toString(),
            status: StatusTypeEnum.NOT_STARTED_YET,
            // webLink: "",
            percentage: 0,
            toolTipDetails: "",
            // reraLink: "",
            // reraNumbers: [""],
            // medias: {
            //   name: "",
            //   key: "",
            //   value: {
            //     url: "",
            //     size: 0,
            //     width: 1024,
            //     height: 1024,
            //     mediaType: "image",
            //   },
            //   isActive: false,
            // },
          },
        },
        {
          key: "projectTimelines",
          isSectionActive: false,
          category: "",
          values: {
            displayName: "",
            isCtaActive: false,
            // imageTitle: "",
            dateOfCompletion: new Date(Date.now())
              .toLocaleString()
              .split(",")[0]
              .toString(),
            status: StatusTypeEnum.NOT_STARTED_YET,
            // webLink: "",
            percentage: 0,
            toolTipDetails: "",
            // reraLink: "",
            // reraNumbers: [""],
            // medias: {
            //   name: "",
            //   key: "",
            //   value: {
            //     url: "",
            //     size: 0,
            //     width: 1024,
            //     height: 1024,
            //     mediaType: "image",
            //   },
            //   isActive: false,
            // },
          },
        },
        {
          key: "projectTimelines",
          isSectionActive: false,
          category: "",
          values: {
            displayName: "",
            isCtaActive: false,
            // imageTitle: "",
            dateOfCompletion: new Date(Date.now())
              .toLocaleString()
              .split(",")[0]
              .toString(),
            status: StatusTypeEnum.NOT_STARTED_YET,
            // webLink: "",
            percentage: 0,
            toolTipDetails: "",
            // reraLink: "",
            // reraNumbers: [""],
            // medias: {
            //   name: "",
            //   key: "",
            //   value: {
            //     url: "",
            //     size: 0,
            //     width: 1024,
            //     height: 1024,
            //     mediaType: "image",
            //   },
            //   isActive: false,
            // },
          },
        },
        {
          key: "projectTimelines",
          isSectionActive: false,
          category: "",
          values: {
            displayName: "",
            isCtaActive: false,
            // imageTitle: "",
            dateOfCompletion: new Date(Date.now())
              .toLocaleString()
              .split(",")[0]
              .toString(),
            status: StatusTypeEnum.NOT_STARTED_YET,
            // webLink: "",
            percentage: 0,
            toolTipDetails: "",
            // reraLink: "",
            // reraNumbers: [""],
            // medias: {
            //   name: "",
            //   key: "",
            //   value: {
            //     url: "",
            //     size: 0,
            //     width: 1024,
            //     height: 1024,
            //     mediaType: "image",
            //   },
            //   isActive: false,
            // },
          },
        },
      ],
    },
    {
      timeLineSectionHeading: "",
      priority: 5,
      timeLines: [
        {
          key: "projectTimelines",
          isSectionActive: false,
          category: "",
          values: {
            displayName: "",
            isCtaActive: false,
            // imageTitle: "",
            // dateOfCompletion: "",
            status: StatusTypeEnum.NOT_STARTED_YET,
            // webLink: "",
            percentage: 0,
            toolTipDetails: "",
            // reraLink: "",
            // reraNumbers: [""],
            // medias: {
            //   name: "",
            //   key: "",
            //   value: {
            //     url: "",
            //     size: 0,
            //     width: 1024,
            //     height: 1024,
            //     mediaType: "image",
            //   },
            //   isActive: false,
            // },
          },
        },
      ],
    },
  ],
  // ------------------------------keyPillars------------------------------
  isKeyPillarsActive: false,
  keyPillars: {
    heading: "",
    values: [
      {
        name: "",
        icon: {
          name: "",
          key: "",
          value: {
            url: "",
            size: 0,
            width: 1024,
            height: 1024,
            mediaType: "image",
          },
        },
        points: [""],
      },
      {
        name: "",
        icon: {
          name: "",
          key: "",
          value: {
            url: "",
            size: 0,
            width: 1024,
            height: 1024,
            mediaType: "image",
          },
        },
        points: ["", ""],
      },
    ],
  },
  // ----------------------------------isLocationInfrastructure-----------------------
  isLocationInfrastructureActive: false,
  isLocationInfrastructureWebActive: false,
  locationInfrastructure: {
    heading: "",
    values: [
      {
        name: "",
        hours: null,
        minutes: null,
        latitude: null,
        longitude: null,
        icon: {
          name: "",
          key: "",
          value: {
            url: "",
            size: 0,
            width: 1024,
            height: 1024,
            mediaType: "image",
          },
        },
      },
    ],
  },
  // ------------------------------isOffersAndPromotions------------------------
  isOffersAndPromotionsActive: false,
  offersAndPromotions: {
    name: "",
    key: "",
    value: {
      url: "",
      size: 0,
      width: 1024,
      height: 1024,
      mediaType: "image",
    },
  },
  // ------------------------------mappedFaqs------------------------
  mappedFaqs: [],
  mappedCategories: [],
  mappedFaqSectionHeader: "FAQs",
  isMappedFaqSectionHeaderActive: false,
  frequentlyAskedQuestions: [],
  faqsToDisplayInDetails: [],

  reraDetails: {
    companyNameAndAddress: "",
    reraNumbers: [""],

    isReraDetailsActive: false,
    isReraDetailsActiveForWeb: false,
    isReraDocumentsActive: false,
    sectionHeading: "",
    reraDocuments: [
      {
        phaseName: "",
        documents: [
          {
            documentName: "",
            documentMedia: {
              name: "",
              key: "",
              value: {
                mediaType: "PDF",
                url: "",
              },
            },
          },
        ],
      },
    ],
    reraCodes: [{ reraNumber: "", qrCode: "" }],
  },

  // --------------------------------latestMediaGallery------------------------------------------------
  isLatestMediaGalleryActive: false,
  latestMediaGalleryHeading: "latestMediaGalleryHeading",
  latestMediaGallerySectionHeading: "",
  latestMediaGallerySubHeading: "Not Used",
  latestMediaGallery: {
    isCoverImageActive: false,
    isImagesActive: false,
    isVideosActive: false,
    isDroneShootsActive: false,
    isThreeSixtyImagesActive: false,
    isVirtualWalkthroughActive: false,
    images: [],
    videos: [],
    droneShoots: [],
    threeSixtyImages: [],
    virtualWalkthrough: [],
  },

  // -----------------------------------------projectCoverImages-----------------------------------------
  similarInvestmentsWithPriority: [],
  projectCoverImages: {
    homePageMedia: {
      name: "",
      key: "",
      value: {
        url: "",
        size: 0,
        width: 0,
        height: 0,
        mediaType: "image",
      },
    },
    newInvestmentPageMedia: {
      name: "",
      key: "",
      value: {
        url: "",
        size: 0,
        width: 0,
        height: 0,
        mediaType: "image",
      },
    },
    collectionListViewPageMedia: {
      name: "",
      key: "",
      value: {
        url: "",
        size: 0,
        width: 0,
        height: 0,
        mediaType: "image",
      },
    },
    portfolioPageMedia: {
      name: "",
      key: "",
      value: {
        url: "",
        size: 0,
        width: 0,
        height: 0,
        mediaType: "image",
      },
    },
    chatListViewPageMedia: {
      name: "",
      key: "",
      value: {
        url: "",
        size: 0,
        width: 0,
        height: 0,
        mediaType: "image",
      },
    },
    chatPageMedia: {
      name: "",
      key: "",
      value: {
        url: "",
        size: 0,
        width: 0,
        height: 0,
        mediaType: "image",
      },
    },
    mobileCoverMedia: {
      name: "",
      key: "",
      value: {
        url: "",
        size: 0,
        width: 0,
        height: 0,
        mediaType: "image",
      },
    },
  },

  projectIcon: {
    name: "",
    key: "",
    value: {
      url: "",
      size: 0,
      width: 0,
      height: 0,
      mediaType: "image",
    },
  },
  projectPhoneNumber: "",
  otherSectionHeadings: {
    promises: {
      sectionHeading: "",
    },
    testimonials: {
      sectionHeading: "",
    },
    inventoryBucketContents: {
      sectionHeading: "",
      subHeading: "",
    },
    faqSection: {
      sectionHeading: "",
      subHeading: "FAQ Section",
      categoryNameList: ["Select an option or create one"],
    },
  },

  customerGuideLines: {
    name: "",
    key: "",
    value: {
      mediaType: "pdf",
      size: 0,
      width: 0,
      height: 0,
      url: "",
    },
    isActive: false,
  },
  priceTransparency: {
    isPriceTransparencyActive: false,
    sectionHeading: "",
    projectName: "",
    priceTransparencyCTAName: "",
    shortDescription: "",
    projects: [
      {
        skuName: "",
        areaStartingFrom: "",
        priceStartingFrom: "",
      },
      {
        skuName: "",
        areaStartingFrom: "",
        priceStartingFrom: "",
      },
      {
        skuName: "",
        areaStartingFrom: "",
        priceStartingFrom: "",
      },
    ],
    termsAndConditions: {
      isTermsAndConditionsActive: false,
      sectionHeading: "",
      description: "",
    },
  },
};

export { InitinalValueProjectContent };
